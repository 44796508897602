<template>
	<div id="checkout-shipping">
		<div v-if="isEdit" class="editable-form">
			<p>Shipping Details</p>
			<div class="name">
				<div class="form-item mr-input" :class="firstNameErrorHandler" id="first-inp">
					<label for="first-name">FIRST NAME</label>
					<input
						v-model="firstName"
						type="text"
						name="first-name"
						id="first-name"
						@keydown="firstNameErrorHandler = ''"
					/>
				</div>
				<div class="form-item" :class="lastNameErrorHandler">
					<label for="last-name">LAST NAME</label>
					<input
						v-model="lastName"
						type="text"
						name="last-name"
						id="last-name"
						@keydown="lastNameErrorHandler = ''"
					/>
				</div>
			</div>
			<div class="form-item" :class="addressStreetErrorHandler">
				<label for="address">ADDRESS</label>
				<input
					v-model="addressStreet"
					type="text"
					name="address"
					id="address"
					@keydown="addressStreetErrorHandler = ''"
				/>
			</div>
			<div class="form-item">
				<label for="street_number">APT/UNIT/SUITE(optional)</label>
				<input
					v-model="addressNumber"
					type="text"
					name="street_number"
					id="street_number"
				/>
			</div>
			<div class="rest-address-info">
				<div class="form-item mr-input" :class="addressCityErrorHandler" id="city-box">
					<label for="city">CITY</label>
					<input @keydown="addressCityErrorHandler = ''" v-model="addressCity" type="text" name="city" id="city" />
				</div>
				<div class="form-item mr-input" id="state-box">
					<label for="state">STATE</label>
					<select v-model="addressState">
						<option v-for="state in states" :key="state[1]" :value="state[1]">
							{{ state[0] }}
						</option>
					</select>
				</div>
				<div class="form-item mr-input" :class="addressZipErrorHandler" id="zipcode-box">
					<label for="zipcode">ZIPCODE</label>
					<input @keydown="addressZipErrorHandler = ''" v-model="addressZip" type="text" name="zipcode" id="zipcode" />
				</div>
				<div class="form-item mr-input" :class="mobileErrorHandler" id="number-box">
					<label for="phone">MOBILE NUMBER</label>
					<div>
					  <span class="phone-prefix">
              +1
            </span>
            <input @keydown="mobileErrorHandler = ''" v-model="phone" type="text" name="phone" id="phone" />
          </div>
					<p>
					  This number will be used for verification
					  <br>
					  Please enter your phone number with no spaces: 4132135672
          </p>
				</div>
			</div>
			<div class="save-container">
				<button @click="saveBtnHandler">SAVE AND CONTINUE</button>
			</div>
		</div>
		<div v-else class="form-details">
			<div class="shipping-address">
				<h5>Shipping Address</h5>
				<p>{{ firstName }} {{ lastName }}</p>
				<p>{{ addressStreet }}</p>
				<p>{{ addressNumber }}</p>
				<p class="city">
					{{ addressCity }}, {{ addressState }}
					{{ addressZip }}
				</p>
				<p>{{ this.phone }}</p>
			</div>
			<div class="email-dates">
				<div class="email">
					<h5>Email</h5>
					<p>{{ user.email }}</p>
				</div>
				<div class="dates">
					<h5>Delivery / Return Dates</h5>
					<p>Delivery Date {{ this.rent_at }} - FREE</p>
					<p>Return Date {{ this.return_at }} - FREE</p>
				</div>
			</div>

			<a @click="isEdit = true">EDIT</a>
		</div>
	</div>
</template>

<script>
	import * as Toastr from "toastr";
	export default {
		props: {
			states: { type: Array },
			addresses: { type: Array },
			user: { type: Object },
			rent_at: { type: String },
			return_at: { type: String },
			securityCheck: { type: Object },
			update_recipient_path: { type: String },
			order: { type: Object }
		},
		components: {},
		data() {
			return {
				isEdit: true,
				firstName: "",
				lastName: "",
				addressStreet: "",
				addressNumber: "",
				addressCity: "",
				addressState: "",
				addressZip: "",
				phone: "",
				address_id: "",
				firstNameErrorHandler: "",
				lastNameErrorHandler: "",
				addressStreetErrorHandler: "",
				addressCityErrorHandler: "",
				addressZipErrorHandler: "",
				phoneErrorHandler: "",
			};
		},
		created() {
			if (this.addresses[0] != null || this.addresses[0] != undefined) {
				(this.firstName = this.addresses[0].first_name),
					(this.lastName = this.addresses[0].last_name),
					(this.addressStreet = this.addresses[0].street),
					(this.addressNumber = this.addresses[0].number),
					(this.addressCity = this.addresses[0].city),
					(this.addressState = this.addresses[0].state),
					(this.addressZip = this.addresses[0].zip),
					(this.phone = this.addresses[0].phone_number);
			}
		},
		mounted() {},
		methods: {
			saveBtnHandler: function() {
				if (
					this.firstName == "" ||
					this.lastName == "" ||
					this.addressStreet == "" ||
					this.addressCity == "" ||
					this.addressState == "" ||
					this.addressZip == "" ||
					this.phone == ""
				) {
					Toastr.error(
						"All fields except APT/UNIT/SUITE are required!",
						"Checkout error"
					);

					if(this.firstName == "") this.firstNameErrorHandler = "error"
					else this.firstNameErrorHandler = ""
					if(this.lastName == "") this.lastNameErrorHandler = "error"
					else this.lastNameErrorHandler = ""
					if(this.addressStreet == "") this.addressStreetErrorHandler = "error"
					else this.addressStreetErrorHandler = ""
					if(this.addressCity == "") this.addressCityErrorHandler = "error"
					else this.addressCityErrorHandler = ""
					if(this.addressZip == "") this.addressZipErrorHandler = "error"
					else this.addressZipErrorHandler = ""
					if(this.phone == "") this.mobileErrorHandler = "error"
					else this.mobileErrorHandler = ""
					return;
				}
				if (this.addresses[0]) {
					if (this.phone != this.addresses[0].phone_number) {
						$.ajax({
							method: "POST",
							data: {},
							url: "/unverify_phone",
							success: function(data) {},
							error: function(data) {}
						});

						this.user.phone_verified = false;
						this.securityCheck.verification = false;
					}
				}
				let reqMethod = "POST";
				let reqUrl = "/my-account/addresses";
				let editedAddress = {
					first_name: this.firstName,
					last_name: this.lastName,
					city: this.addressCity,
					state: this.addressState,
					street: this.addressStreet,
					number: this.addressNumber,
					zip: this.addressZip,
					phone_number: this.phone
				};

				if (this.addresses[0] != null || this.addresses[0] != undefined) {
					editedAddress = this.addresses[0];

					reqMethod = "PUT";
					reqUrl = "/my-account/addresses/" + editedAddress.id;

					editedAddress.first_name = this.firstName;
					editedAddress.last_name = this.lastName;
					editedAddress.city = this.addressCity;
					editedAddress.state = this.addressState;
					editedAddress.street = this.addressStreet;
					editedAddress.number = this.addressNumber;
					editedAddress.zip = this.addressZip;
					editedAddress.phone_number = this.phone;
				}

				this.addresses[0] = editedAddress;

				$.ajax({
					method: reqMethod,
					data: {
						address: editedAddress
					},
					async: false,
					url: reqUrl,
					success: data => {
						this.address_id = data.data.id;
					},
					error: function(data) {
						this.errorChecking = 'red'
					}
				});

				this.setRecipient();
			},
			showForm: function() {
				this.isEdit = true;
			},
			setRecipient() {
				const that = this;
				const data = { order: {} };

				const editedAddress = this.order.shipping;

				editedAddress.address_id = this.address_id;
				editedAddress.street = this.addressStreet;
				editedAddress.number = this.addressNumber;
				editedAddress.city = this.addressCity;
				editedAddress.state = this.addressState;
				editedAddress.zip = this.addressZip;
				editedAddress.first_name = this.firstName;
				editedAddress.last_name = this.lastName;
				editedAddress.phone = this.phone;
				editedAddress.email = this.user.email;

				data.order.shipping = editedAddress;
				data.order.use_shipping_as_billing = true;

				$.ajax({
					method: "PUT",
					url: that.update_recipient_path,
					data: data,
					async: false,
					success: data => {
						Toastr.success("Address was added.", "Success");

						this.securityCheck.shipping = true;
						this.isEdit = false;

						const veriBox = $("#checkout-verification");
						veriBox.css("height", "max-content");

						this.$emit("openVerification");
					},
					error: data => {
						Toastr.error("Wrong address.", "Error");
						this.securityCheck.shipping = false;
						this.addressStreetErrorHandler = "error"
						this.addressCityErrorHandler = "error"
						this.addressZipErrorHandler = "error"
					}
				});
			}
		}
	};
</script>

<style lang="scss">
.error {
	color: red;
	input {
		border: solid 1px red;
	}
}

span.phone-prefix{
  position: absolute;
  color: black;
  font-family: 'Utile Regular';
  font-weight: 300;
  font-size: 15px;
  padding-left: 8px;
  pointer-events: none;
  line-height: 35px;
  height: 35px;
}

input#phone {
  padding-left: 25px;
}
</style>
