<template>
  <div class="checkout-container" :id="planId">
    <div class="nav" id="nav-desktop">
      <div class="before-chevron" />
      <a href="/" class="back-to-cart">Back to Shopping Cart</a>
    </div>
    <div class="main-box">
      <div class="checkout-form">
        <h1 class="checkout-title">SECURE CHECKOUT</h1>
        <div class="accordions">
          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">1</p>
              <span>Shipping Information</span>
            </div>
            <shipping
              :states="usaStates"
              :addresses="shipping_addresses"
              :user="user"
              :rent_at="rent_at"
              :return_at="return_at"
              :securityCheck="securityCheck"
              :update_recipient_path="payment.path"
              :order="order"
              @openVerification="verificationOpened = true"
            />
          </div>
          <div class="spacer" />
          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">2</p>
              <span>Identity Verification</span>
            </div>
            <verification
              :user="user"
              :addresses="shipping_addresses"
              :securityCheck="securityCheck"
              :personaEnvironment="persona_environment"
              :personaTemplateId="persona_template_id"
              :personaEnvironmentId="persona_environment_id"
              :verificationOpened="verificationOpened"
              :returningCustomer="returning_customer"
            />
          </div>
          <div class="spacer" />
          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">3</p>
              <span>Billing Information</span>
            </div>
            <billing
              :states="usaStates"
              :addresses="billing_addresses"
              :shippingAddress="shipping_addresses[0]"
              :payment="payment"
              :securityCheck="securityCheck"
              :order="order"
              :update_recipient_path="payment.path"
              :user="user"
            />
          </div>
          <div class="spacer" />
          <div class="accordion">
            <div class="ac-title">
              <p class="checkout-number">4</p>
              <span>Review Order</span>
            </div>
            <div class="collapsed" id="checkout-review">
              <p>
                Please review your order on the right and hit PLACE ORDER when
                ready. Your credit card will be charged when your order is
                shipped.
              </p>
              <div class="checkboxes">
                <div>
                  <input
                    v-model="securityCheck.terms"
                    name="agreement"
                    type="checkbox"
                  />
                  <label for="agreement">
                    I have read and accepted the
                    <a class="tos-link" href="/terms-and-conditions">
                      Terms and Conditions
                    </a>
                    .
                  </label>
                </div>
                <div>
                  <input
                    v-model="securityCheck.disclosure"
                    name="disclosure"
                    type="checkbox"
                  />
                  <label for="disclosure">
                    Due to the nature of the services that we provide we reserve
                    the right to reject jewelry rental services to individuals,
                    who do not meet the minimum merit of good credit qualifications
                    and/or do not pass background and identity verification. In 
                    the event that a user is not approved for services, an order
                    cancellation fee may apply.
                  </label>
                </div>
              </div>
              <div class="save-container">
                <button id="submit-order">PLACE ORDER</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <order-details
        :products="items"
        :order="order"
        :rent_at="rent_at"
        :return_at="return_at"
        :isSubscription="isSubscription"
      />
    </div>
  </div>
</template>

<script>
import OrderDetails from "./order_details";
import Shipping from "./steps/shipping";
import Billing from "./steps/billing";
import Verification from "./steps/verification";
import JsonApi from "../../../vendor/json-api";
import { states } from "../../../../data/checkoutData.js";
import * as Toastr from "toastr";
import GtmEvents from "../../gtm_events";
export default {
  props: {
    payment: { type: Object, required: true },
    shipping_addresses: { type: Array, required: true },
    billing_addresses: { type: Array, required: true },
    new_address_path: { type: String },
    order: { type: Object, required: true },
    user: { type: Object, required: true },
    persona_environment: { type: String, required: true },
    persona_template_id: { type: String, required: true },
    persona_environment_id: { type: String, required: true },
    returning_customer: { type: Boolean, required: true }
  },
  components: {
    "order-details": OrderDetails,
    shipping: Shipping,
    verification: Verification,
    billing: Billing,
  },
  computed: {
    planId() {
      if (this.order.shopping_mode === "rbtd") {
        return "";
      } else {
        return "sub-checkout";
      }
    },
    isSubscription() {
      if (this.order.shopping_mode !== "rbtd") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      items: JsonApi.deserialize(gon.payload.order_products).data,
      usaStates: states,
      rent_at: "",
      return_at: "",
      securityCheck: {
        shipping: false,
        verification: false,
        billing: false,
        terms: false,
        disclosure: false,
      },
      verificationOpened: false,
    };
  },
  created() {
    if (!this.user.email_verified) {
      Toastr.error("Please confirm Your e-mail before proceeding!");
      setTimeout(() => {
        document.location.href = "/";
      }, 3000);
    }

    GtmEvents.trackCartEvents('begin_checkout')

    this.rent_at = this.formatDate(this.order.rent_at, -0);
    this.return_at = this.formatDate(this.order.return_at, 0);
  },
  methods: {
    getOrdinalNum: function (number) {
      let selector;

      if (number <= 0) {
        selector = 4;
      } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
      } else {
        selector = number % 10;
      }

      return number + ["th", "st", "nd", "rd", ""][selector];
    },
    getMonthShortName: function (num) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[num];
    },
    formatDate: function (date, daysOffset) {
      const dateObj = new Date(date);
      dateObj.setDate(dateObj.getDate() + daysOffset);
      return `${this.getOrdinalNum(dateObj.getDate())} ${this.getMonthShortName(
        dateObj.getMonth()
      )}, ${dateObj.getFullYear()}`;
    },
  },
};
</script>

<style lang="scss">
</style>
