<template>
  <div class="collapsed" id="checkout-billing">
    <div id="checkout-form">
      <div class="billing-title">
        <img
          class="fake-radio"
          src="~images/app/checkout/fake-radio-btn.svg"
          alt="radio"
        />
        <p>Credit Card</p>
      </div>
      <img src="~images/app/checkout/cards-checkout.webp" alt="cards" />

      <div id="dropin-container"></div>

      <div class="checkbox-text">
        <input
          v-model="isBillingAddressNeeded"
          name="different-billing"
          type="checkbox"
          @click="heightAdjust"
        />
        <label for="different-billing"
          >Billing address is different than Shipping Address</label
        >
      </div>
      <div class="billing-address" v-if="isBillingAddressNeeded">
        <div class="form-item">
          <label for="address">ADDRESS</label>
          <input
            v-model="addressStreet"
            type="text"
            name="address"
            id="address"
          />
        </div>
        <div class="form-item">
          <label for="apartment">APT/UNIT/SUITE(optional)</label>
          <input
            v-model="addressNumber"
            type="text"
            name="apartment"
            id="apartment"
          />
        </div>
        <div class="rest-address-info">
          <div class="form-item mr-input" id="city-box">
            <label for="city">CITY</label>
            <input v-model="addressCity" type="text" name="city" id="city" />
          </div>
          <div class="form-item mr-input" id="state-box">
            <label for="state">STATE</label>
            <select v-model="addressState" class="mb-3">
              <option v-for="state in states" :key="state[1]" :value="state[1]">
                {{ state[0] }}
              </option>
            </select>
          </div>
          <div class="form-item mr-input" id="zipcode-box">
            <label for="zipcode">ZIPCODE</label>
            <input
              v-model="addressZip"
              type="text"
              name="zipcode"
              id="zipcode"
            />
          </div>
        </div>
      </div>

      <div class="save-container">
        <button @click="saveBtnHandler">SAVE AND CONTINUE</button>
      </div>
    </div>
    <div class="form-details" id="billing-display">
      <div v-if="isBillingAddressNeeded">
        <div class="shipping-address display-billing">
          <h5>Billing Address</h5>
          <p>{{ addressStreet }}</p>
          <p>{{ addressNumber }}</p>
          <p class="city">
            {{ addressCity }}, {{ addressState }}
            {{ addressZip }}
          </p>
        </div>
      </div>

      <div v-else class="shipping-address">
        <h5>Billing Address</h5>
        <p v-if="shippingAddress">{{ shippingAddress.street }}</p>
        <p v-if="shippingAddress">{{ shippingAddress.number }}</p>
        <p v-if="shippingAddress" class="city">
          {{ shippingAddress.city }}, {{ shippingAddress.state }}
          {{ shippingAddress.zip }}
        </p>
      </div>
      <a id="billing-edit" @click="toggleModes">EDIT</a>
    </div>
  </div>
</template>

<script>
import * as Toastr from "toastr";
export default {
  props: {
    states: { type: Array },
    addresses: { type: Array },
    payment: { type: Object, required: true },
    securityCheck: { type: Object },
    update_recipient_path: { type: String },
    order: { type: Object },
    shippingAddress: { type: Object },
    user: { type: Object },
  },
  components: {},
  data() {
    return {
      isBillingAddressNeeded: false,
      addressStreet: "",
      addressNumber: "",
      addressCity: "",
      addressState: "",
      addressZip: "",
      address_id: "",
      isEdit: true,
    };
  },
  mounted() {
    var button = document.querySelector("#submit-order");
    var that = this;

    braintree.dropin.create(
      {
        authorization: this.payment.token,
        container: "#dropin-container",
        dataCollector: true,
      },
      function (createErr, instance) {
        if(createErr) {
          console.log(createErr);
          return;
        }

        button.addEventListener("click", function () {
          if (button.disabled) {
            return;
          }

          if (that.securityCheck.terms == false) {
            Toastr.error(
              "Terms and conditions have to be accepted to place the order"
            );
            return;
          }

          if (that.securityCheck.disclosure == false) {
            Toastr.error(
              "Terms and conditions disclosure have to be accepted to place the order"
            );
            return;
          }

          if (
            that.securityCheck.shipping == false ||
            that.securityCheck.verification == false ||
            that.securityCheck.billing == false
          ) {
            Toastr.error(
              "Some of previous forms proceed buttons weren't clicked.",
              "Security check error."
            );
            return;
          }

          button.disabled = true;
          button.textContent = "Loading ...";
          instance.requestPaymentMethod(function (error, payload) {
            if (error) {
              button.disabled = false;
              button.textContent = "PLACE ORDER";
              instance.clearSelectedPaymentMethod();
              Toastr.error(
                "There was error with your payment. " +
                  error.message +
                  " Please try again.",
                "Payment error."
              );
              that.showErrors();
            } else {
              $("#payment_method_nonce").val(payload.nonce);
              $("#device_data").val(payload.deviceData);
              var form = $("#payment_method_nonce").parents("form")
              $.ajax({
                method: "POST",
                dataType: 'JSON',
                data: form.serialize(),
                url: '/cart/checkout',
                success: function (data) {
                  Toastr.success(
                    "Thank you for your payment, you will be redirected in a second.",
                    "Payment successful"
                  );
                  
                  const order = data.order
                  const items = data.items

                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'purchase',
                    ecommerce: {
                      currency: 'USD',
                      value: order.price,
                      transaction_id: order.id,
                      coupon: order.coupon,
                      items: items
                    }
                  })

                  window.location = `/thank-you-page/${order.id}`
                },
                error: function(request) {
                  Toastr.error(request.responseJSON.error, 'Checkout error')
                  button.disabled = false;
                  button.textContent = "PLACE ORDER";
                }
              })
            }
          });
        });
      }
    );
  },
  methods: {
    saveBtnHandler: function () {
      if (this.isBillingAddressNeeded == true) {
        const isSuccess = this.addModifyBillingAddress();

        if (!isSuccess) {
          return;
        }
      } else {
        const data = { order: {} };
        data.order.use_shipping_as_billing = true;

        $.ajax({
          method: "PUT",
          url: this.update_recipient_path,
          data: data,
          async: false,
          success: function (data) {},
          error: function (data) {},
        });
      }

      this.securityCheck.billing = true;

      this.toggleModes();

      const reviewBox = $("#checkout-review");
      reviewBox.css("height", reviewBox[0].scrollHeight);

      const billingBox = $("#checkout-billing");

      billingBox.css("height", "max-content");
    },
    toggleModes: function () {
      if (this.isEdit) {
        $("#checkout-form").css("display", "none");
        $("#billing-edit").css("display", "inline-block");
        $("#billing-display").css("display", "flex");

        this.isEdit = false;
      } else {
        $("#checkout-form").css("display", "block");
        $("#billing-edit").css("display", "none");
        $("#billing-display").css("display", "none");

        this.isEdit = true;
      }
    },
    showErrors: function () {
      if (this.isEdit === true) {
        return;
      }
      $("#checkout-form").css("display", "block");
      $("#billing-edit").css("display", "none");
      $("#billing-display").css("display", "none");

      this.isEdit = true;
    },
    heightAdjust: function () {
      const billingBox = $("#checkout-billing");
      billingBox.css("height", "max-content");
    },
    addModifyBillingAddress: function () {
      if (
        this.addressStreet == "" ||
        this.addressCity == "" ||
        this.addressState == "" ||
        this.addressZip == ""
      ) {
        Toastr.error(
          "All fields except APT/UNIT/SUITE are required!",
          "Checkout error"
        );
        return false;
      }

      let reqMethod = "POST";
      let reqUrl = "/my-account/addresses";
      let editedAddress = {
        city: this.addressCity,
        state: this.addressState,
        street: this.addressStreet,
        number: this.addressNumber,
        zip: this.addressZip,
        address_type: "billing",
      };

      if (this.addresses[0] != null || this.addresses[0] != undefined) {
        editedAddress = this.addresses[0];

        reqMethod = "PUT";
        reqUrl = "/my-account/addresses/" + editedAddress.id;

        editedAddress.city = this.addressCity;
        editedAddress.state = this.addressState;
        editedAddress.street = this.addressStreet;
        editedAddress.zip = this.addressZip;
        editedAddress.address_type = "billing";
        if (this.addressNumber != "") {
          editedAddress.number = this.addressNumber;
        }
      }

      $.ajax({
        method: reqMethod,
        data: {
          address: editedAddress,
        },
        async: false,
        url: reqUrl,
        success: function (data) {
          this.address_id = data.data.id;
        },
        error: function (data) {
          Toastr.error("Address hasn't changed!", "Server Error");
        },
      });

      this.setRecipientBilling();
      return true;
    },
    setRecipientBilling: function () {
      const that = this;
      const data = { order: {} };

      const editedAddress = this.order.billing;

      editedAddress.address_id = this.address_id;
      editedAddress.street = this.addressStreet;
      editedAddress.number = this.number;
      editedAddress.city = this.addressCity;
      editedAddress.state = this.addressState;
      editedAddress.zip = this.addressZip;
      editedAddress.first_name = this.firstName;
      editedAddress.last_name = this.lastName;
      editedAddress.phone = this.phone;
      editedAddress.email = this.user.email;

      data.order.billing = editedAddress;
      data.order.use_shipping_as_billing = false;

      $.ajax({
        method: "PUT",
        url: that.update_recipient_path,
        data: data,
        async: false,
        success: function (data) {
          Toastr.success("Address was updated.", "Success");
        },
        error: function (data) {
          Toastr.error("Wrong Address.", "Error");
        },
      });
    },
  },
};
</script>

<style lang="scss">
</style>
